import * as React from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import {useNavigate} from 'react-router-dom';

const boxLayout = {
  display: {xs: 'flex', md: 'grid'},
  gridTemplateColumns: 'repeat(3, 24.8%)',
  justifyContent: 'center',
  gap: {xs: 'unset', md: '1rem'},
  padding: {xs: 'unset', md: '1rem'},
  flexWrap: {xs: 'wrap', md: 'unset'},
  minWidth: 300,
  width: {xs: '100%', md: 'unset'}
};


const images = [
  {
    url: 'images/buttons/fertilizers.jpg',
    title: 'Удобрения',
    to: '/fertilizers',
    width: '100%',
  },
  {
    url: 'images/buttons/ride.jpg',
    title: 'Покататься',
    to: '/ride',
    width: '100%',
  },
  {
    url: 'images/buttons/Introduction-course.jpg',
    title: 'курс "Знакомство"',
    to: '/',
    width: '100%',
  },
  {
    url: 'images/buttons/levada-time.jpg',
    title: 'Время в леваде',
    to: '/',
    width: '100%',
  },
  {
    url: 'images/buttons/ammunition.jpg',
    title: 'Аммуниция',
    to: '/',
    width: '100%',
  },
  {
    url: 'images/buttons/stables.jpg',
    title: 'Конюшни Кировской области',
    to: '/',
    width: '100%',
  },
];

const ImageButton = styled(ButtonBase)(({theme}) => ({
  position: 'relative',
  height: 200,
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 100,
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 40%',
});

const Image = styled('span')(({theme}) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({theme}) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({theme}) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  bottom: -2,
  left: 'calc(50% - 9px)',
  transition: theme.transitions.create('opacity'),
}));


export default function Main() {
  const navigate = useNavigate();
  return (
    <Box sx={boxLayout}>
      {images.map((image) => (
        <ImageButton
          focusRipple
          key={image.title}
          style={{width: image.width}}
          onClick={() => navigate(image.to)}
        >
          <ImageSrc style={{backgroundImage: `url(${image.url})`}}/>
          <ImageBackdrop className="MuiImageBackdrop-root"/>
          <Image>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              sx={{position: 'relative', p: 4, pt: 2, pb: (theme) => `calc(${theme.spacing(1)} + 6px)`}}
            >
              {image.title}
              <ImageMarked className="MuiImageMarked-root"/>
            </Typography>
          </Image>
        </ImageButton>
      ))}
    </Box>
  );
}
