import React from 'react';
import {Box, Button} from "@mui/material";


export default function Reservation() {
  return (
    <Box height={53}>
      <Button variant="contained" color="success" style={{width: '100%', height: '100%'}}>
        Забронировать
      </Button>
    </Box>
  );
}

