import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import FolderIcon from '@mui/icons-material/Folder';
import HomeIcon from '@mui/icons-material/Home';
import BedroomBabyIcon from '@mui/icons-material/BedroomBaby';
import {ReactComponent as Fertilizers} from '../icons/Fertilizers.svg';
import {useNavigate} from "react-router-dom";
import {SvgIcon} from "@mui/material";

export default function LabelBottomNavigation() {
  const [value, setValue] = React.useState(window.location.pathname !== '/' ? window.location.pathname.slice(1) : 'home');
  React.useEffect(() => setValue(window.location.pathname !== '/' ? window.location.pathname.slice(1) : 'home'));
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent | null, newValue: string) => {
    switch (newValue) {
      case 'home':
        navigate('/');
        break;
      case 'fertilizers':
        navigate(`/${newValue}`);
        break;
      case 'ride':
        navigate(`/${newValue}`);
        break;
      case 'folder':
        navigate(`/${newValue}`);
        break;
      default:
        navigate(`/как-вы-сюда-попали`);
    }
    setValue(newValue);
  };

  return (
    <BottomNavigation sx={{width: 500, backgroundColor: 'unset'}} value={value} onChange={handleChange}>
      <BottomNavigationAction label="Главная" value="home" icon={<HomeIcon/>} sx={{color: {selected: 'red'}}}/>
      <BottomNavigationAction label="Удобрения" value="fertilizers" icon={<SvgIcon component={Fertilizers} inheritViewBox/>}/>
      <BottomNavigationAction label="Катание" value="ride" icon={<BedroomBabyIcon/>}/>
      <BottomNavigationAction label="Папка" value="folder" icon={<FolderIcon/>}/>
    </BottomNavigation>
  );
}
