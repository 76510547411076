import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import SvgIcon from '@mui/icons-material/Menu';
import { ReactComponent as Logo } from '../Logo.svg';
import {Link} from "react-router-dom";
import LabelBottomNavigation from "./LabelBottomNavigation";



function ResponsiveAppBar() {

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to={'/'}>
            <SvgIcon component={Logo} inheritViewBox style={{width: '2.4em', height: '2.8em'}}/>
          </Link>
          <LabelBottomNavigation/>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
