import React, {Component} from 'react';
import {DataGrid, GridColDef, GridRowsProp} from '@mui/x-data-grid';
import Box from "@mui/material/Box";
import Reservation from "./Reservation";



class Fertilizers extends Component <any, any> {
  constructor(props: any) {
    super(props);
  }


  render() {
    const rows: GridRowsProp = [
      { id: 'Звказ №1', bagsinstock: '20', bookedbags: '20', pickupbefore: '24.03.2023' },
      { id: 'Звказ №2', bagsinstock: '14', bookedbags: '20', pickupbefore: '' },
      { id: 'Звказ №3', bagsinstock: '0', bookedbags: '3', pickupbefore: '' },
    ];

    const columns: GridColDef[] = [
      { field: 'id', headerName: '', width: 200 },
      { field: 'bagsinstock', headerName: 'Имеется мешков', width: 200 },
      { field: 'bookedbags', headerName: 'Забронировано мешков', width: 200 },
      { field: 'pickupbefore', headerName: 'Забрать до', width: 200 },
    ];

    return (
      <Box className={'fertilizers'}>
        <DataGrid
          density={'comfortable'}
          autoPageSize
          showColumnRightBorder
          showCellRightBorder
          hideFooterPagination
          components={{
            Footer: Reservation,
          }}
          rows={rows} columns={columns} sx={{ height: `calc(72px + 67px * ${rows.length} + 55px)`, width: {xs: 'calc(100vw - 2rem)', md: `calc(201px * ${columns.length})`}}}
        />
      </Box>
    );
  }
}

export default Fertilizers;
