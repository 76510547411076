import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import RideTable from './components/RideTable';
import Fertilizers from "./components/Fertilizers/Fertilizers";
import Main from "./components/Main";
import {createTheme, ThemeProvider} from "@mui/material";

const theme = createTheme({
  palette: {
    "mode": "light",
    "primary": {"light": "#757ce8", "main": "#768c0c", "dark": "#002884", "contrastText": "#191834"},
    "secondary": {"light": "#ff7961", "main": "#f44336", "dark": "#ba000d", "contrastText": "#dee5b6"},
    "common": {"black": "#000", "white": "#fff"},
    "error": {"main": "#d32f2f", "light": "#ef5350", "dark": "#c62828", "contrastText": "#fff"},
    "warning": {"main": "#ed6c02", "light": "#ff9800", "dark": "#e65100", "contrastText": "#fff"},
    "info": {"main": "#0288d1", "light": "#03a9f4", "dark": "#01579b", "contrastText": "#fff"},
    "success": {"main": "#2e7d32", "light": "#4caf50", "dark": "#1b5e20", "contrastText": "#fff"},
    "grey": {
      "50": "#fafafa",
      "100": "#f5f5f5",
      "200": "#eeeeee",
      "300": "#e0e0e0",
      "400": "#bdbdbd",
      "500": "#9e9e9e",
      "600": "#757575",
      "700": "#616161",
      "800": "#424242",
      "900": "#212121",
      "A100": "#f5f5f5",
      "A200": "#eeeeee",
      "A400": "#bdbdbd",
      "A700": "#616161"
    },
    "contrastThreshold": 3,
    "tonalOffset": 0.2,
    "text": {"primary": "rgba(0, 0, 0, 0.87)", "secondary": "rgba(0, 0, 0, 0.6)", "disabled": "rgba(0, 0, 0, 0.38)"},
    "divider": "rgba(0, 0, 0, 0.12)",
    "background": {"paper": "#fff", "default": "#fff"},
    "action": {
      "active": "rgba(0, 0, 0, 0.54)",
      "hover": "rgba(0, 0, 0, 0.04)",
      "hoverOpacity": 0.04,
      "selected": "rgba(0, 0, 0, 0.08)",
      "selectedOpacity": 0.08,
      "disabled": "rgba(0, 0, 0, 0.26)",
      "disabledBackground": "rgba(0, 0, 0, 0.12)",
      "disabledOpacity": 0.38,
      "focus": "rgba(0, 0, 0, 0.12)",
      "focusOpacity": 0.12,
      "activatedOpacity": 0.12
    }
  }
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <ResponsiveAppBar/>
          <Routes>
            <Route path='/' element={<Main/>}/>
            <Route path='/fertilizers' element={<Fertilizers/>}/>
            <Route path='/ride' element={<RideTable/>}/>
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
